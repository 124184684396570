import React from 'react'
// import { graphql } from 'gatsby'
import { Image, ResponsiveContext } from 'grommet'
import Helmet from 'react-helmet'
import Layout from '../components/layout'
import { Box, Button } from 'grommet'
import favicon from '../images/favicon.ico'
import { StyledText } from '../components'
import Drawings from '../assets/drawingshomepage2.png'

// const InfoSection = ({ children, name }) => (
//   <Box gap="small">
//     <Box
//       pad={{ vertical: 'small' }}
//       border={{ side: 'bottom', color: 'light-5' }}
//     >
//       <StyledText size="small" weight="bold">
//         {name}
//       </StyledText>
//     </Box>
//     {children}
//   </Box>
// )

const RootIndex = ({ data }) => {
  // const [selectedImage, setSelectedImage] = useState(
  //   data.shopifyProduct.images[0].localFile.childImageSharp.fluid
  // )

  return (
    <Layout location={'/'} isLanding>
      <Helmet title="carwash">
        <link rel="icon" href={favicon} />
      </Helmet>
      <ResponsiveContext.Consumer>
        {size => (
          <Box
            // background={{ image: `url(${BackgroundPic})`, size: 'cover' }}
            // background={{ color: 'white', opacity: 'medium' }}
            gap="medium"
            fill
            justify="center"
            align="center"
          >
            <Box width={{ max: size !== 'small' ? '600px' : '100%' }}>
              <Image src={Drawings} fit="contain" />
            </Box>
            <Button
              alignSelf="center"
              label="Watch Now"
              primary
              href="https://www.youtube.com/watch?v=1I8LkGjo_LA"
              target="_blank"
            />
          </Box>
        )}
      </ResponsiveContext.Consumer>
    </Layout>
  )
}

// export const pageQuery = graphql`
//   query {
//     shopifyProduct(handle: { eq: "carwashhoodie" }) {
//       id
//       title
//       handle
//       productType
//       description
//       descriptionHtml
//       shopifyId
//       options {
//         id
//         name
//         values
//       }
//       variants {
//         id
//         title
//         price
//         availableForSale
//         shopifyId
//         selectedOptions {
//           name
//           value
//         }
//       }
//       priceRange {
//         minVariantPrice {
//           amount
//           currencyCode
//         }
//         maxVariantPrice {
//           amount
//           currencyCode
//         }
//       }
//       images {
//         originalSrc
//         id
//         localFile {
//           childImageSharp {
//             fluid(maxWidth: 910) {
//               ...GatsbyImageSharpFluid_withWebp_tracedSVG
//             }
//           }
//         }
//       }
//     }
//   }
// `
export default RootIndex
